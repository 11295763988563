import React from "react";

export const OdysseyLogo: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox={`0 0 64 64`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="29.6953"
        y="40.8231"
        width="4.60906"
        height="23.177"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="29.6953"
        width="4.60906"
        height="23.177"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="41.8506"
        y="1.46527"
        width="4.60906"
        height="11.5885"
        transform="rotate(22 41.8506 1.46527)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="22.2168"
        y="50.0605"
        width="4.60906"
        height="11.5885"
        transform="rotate(22 22.2168 50.0605)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="17.876"
        y="3.19336"
        width="4.60906"
        height="11.5885"
        transform="rotate(-22 17.876 3.19336)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="37.5098"
        y="51.7886"
        width="4.60906"
        height="11.5885"
        transform="rotate(-22 37.5098 51.7886)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="1.4668"
        y="22.1489"
        width="4.60906"
        height="11.5885"
        transform="rotate(-68 1.4668 22.1489)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="50.0615"
        y="41.7822"
        width="4.60906"
        height="11.5885"
        transform="rotate(-68 50.0615 41.7822)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="3.19336"
        y="46.1243"
        width="4.60906"
        height="11.5885"
        transform="rotate(-112 3.19336 46.1243)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="51.7881"
        y="26.491"
        width="4.60906"
        height="11.5885"
        transform="rotate(-112 51.7881 26.491)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        y="34.3047"
        width="4.60906"
        height="23.177"
        transform="rotate(-90 0 34.3047)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="40.8232"
        y="34.3048"
        width="4.60906"
        height="23.177"
        transform="rotate(-90 40.8232 34.3048)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="11.002"
        y="56.2555"
        width="4.60906"
        height="23.177"
        transform="rotate(-135 11.002 56.2555)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="39.8682"
        y="27.3887"
        width="4.60906"
        height="23.177"
        transform="rotate(-135 39.8682 27.3887)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="56.2568"
        y="52.9977"
        width="4.60906"
        height="23.177"
        transform="rotate(135 56.2568 52.9977)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
      <rect
        x="27.3906"
        y="24.1309"
        width="4.60906"
        height="23.177"
        transform="rotate(135 27.3906 24.1309)"
        fill="white"
        style={{ fill: "white", opacity: 1 }}
      />
    </svg>
  );
};
