import { useEffect, useState } from "react";

import { supabaseClient } from "../utility";

interface BlobStorageTokenError {
  message: string;
}

export function useBlobStorageToken(
  storageAccount: string,
  permissions = "cwrlf",
  duration = 60 * 60
): [boolean, BlobStorageTokenError | null, string | null] {
  const [storageToken, setStorageToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<BlobStorageTokenError | null>(null);
  useEffect(() => {
    async function getStorageToken() {
      setIsLoading(true);
      const { data, error } = await supabaseClient.auth.getSession();
      if (error || !data?.session?.access_token) {
        setError({
          message: `Error while fetching session: ${
            error || "No access token"
          }`,
        });
        setIsLoading(false);
        return;
      }
      const access_token = data?.session?.access_token;
      try {
        const res = await fetch(
          "https://vizglusznacdygtioulv.supabase.co/functions/v1/generate-blob-storage-key",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
            body: JSON.stringify({
              storage_account: storageAccount,
              duration: duration,
              permissions: permissions,
            }),
          }
        );
        if (res.status !== 200) {
          throw new Error(`${res.status}`);
        }
        const payload = await res.json();
        setStorageToken(payload.blob_storage_token);
        setIsLoading(false);
      } catch (e) {
        setError({
          message: `Error while fetching storage token: ${e}`,
        });
        setIsLoading(false);
      }
    }
    getStorageToken();
  }, [storageAccount, duration, permissions]);
  return [isLoading, error, storageToken];
}
