import { useUpdate, useInvalidate } from "@refinedev/core";

export const useAssetDelete = () => {
  const { mutate } = useUpdate();
  const invalidate = useInvalidate();
  const handleDelete = (asset: any) => {
    mutate({
      resource: "assets",
      values: {
        delete: true,
      },
      id: asset.id,
      successNotification: () => {
        // we get invalidation of the asset for free, but since data_processed_glb is view
        // that reads from assets, we need to invalidate that too
        invalidate({ resource: "data_processed_glb", invalidates: ["list"] });
        asset.delete = true;
        return {
          message: `Asset ${asset.id} flagged for deletion`,
          type: "success",
        };
      },
    });
  };
  return handleDelete;
};
