import { useState } from "react";
import { AssetCardList } from "./AssetCardList";
import { AssetInspector } from "../../components/AssetInspector";
import { Flex } from "antd";
import { IResourceComponentsProps } from "@refinedev/core";
import Asset from "../../lib/Asset";

export const AssetListContainer: React.FC<IResourceComponentsProps> = (
  props
) => {
  const [asset, setAsset] = useState<Asset | null>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const handleAssetSelect = (asset: Asset) => {
    setAsset(asset);
    // TODO(art): animate opening the detail view
    setShowDetail(true);
  };
  const listWidth = showDetail ? 65 : 100;
  return (
    <Flex gap="small">
      {/* -- independent scroll panel for the asset card list  --> */}
      <div
        style={{ width: `${listWidth}%`, overflow: "auto", height: "100vh" }}
      >
        <AssetCardList onAssetSelect={handleAssetSelect} {...props} />
      </div>
      {showDetail ? (
        <div
          style={{
            width: "35%",
            maxWidth: "1024px",
            justifyContent: "flex-end",
            height: "100vh",
            overflow: "auto",
          }}
        >
          {asset === null ? (
            <div>No asset selected</div>
          ) : (
            <AssetInspector asset={asset} />
          )}
        </div>
      ) : null}
    </Flex>
  );
};
