import { useEffect, useRef, useState } from "react";
import { Image as ImageView } from "antd";
import Asset from "../lib/Asset";

interface ITurntableViewerProps {
  asset: Asset | null;
}

interface TurntableImage {
  url: string;
  image: HTMLImageElement;
}

class TurntableAnimator {
  asset: Asset | null;
  canvas: HTMLCanvasElement | null;
  parent: HTMLDivElement | null;
  context: CanvasRenderingContext2D | null;
  images: TurntableImage[];
  currentFrame: number;
  intervalId: NodeJS.Timeout | null;
  running: boolean;

  constructor(
    canvas: HTMLCanvasElement | null,
    asset: Asset | null,
    parent: HTMLDivElement | null
  ) {
    this.canvas = canvas;
    this.asset = asset;
    this.parent = parent;
    this.update(this.canvas, this.asset, this.parent);
    this.context = null;
    this.images = [];
    this.currentFrame = 0;
    this.intervalId = null;
    this.running = false;
    this.start();
  }

  frame() {
    if (this.canvas && this.context && this.images.length > 0) {
      const img = this.images[this.currentFrame];
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.drawImage(
        img.image,
        0,
        0,
        img.image.width,
        img.image.height,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      this.currentFrame += 1;
      if (this.currentFrame >= this.images.length) {
        this.currentFrame = 0;
      }
    }
  }

  start() {
    this.running = true;
    clearInterval(this.intervalId || 0);
    this.intervalId = setInterval(this.frame.bind(this), 250);
  }

  stop() {
    this.running = false;
    clearInterval(this.intervalId || 0);
  }

  update(
    canvas: HTMLCanvasElement | null,
    asset: Asset | null,
    parent: HTMLDivElement | null
  ) {
    this.canvas = canvas;
    this.asset = asset;
    this.parent = parent;
    if (this.canvas && this.parent) {
      this.context = this.canvas.getContext("2d");
      this.canvas.width = parent?.getBoundingClientRect().width || 512;
      this.canvas.height = this.canvas.width;
    }
    if (this.asset) {
      this.images = [];
      for (let i = 1; i <= 16; i++) {
        const url = this.asset.makeAuthorizedURL
          ? this.asset.makeAuthorizedURL(
              `turntable/turntable_${i.toString().padStart(4, "0")}.png`
            )
          : "";
        const image = new Image();
        image.src = url;
        this.images.push({ url, image });
      }
    }
  }
}

export const TurntableViewer: React.FC<ITurntableViewerProps> = ({ asset }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [animator] = useState<TurntableAnimator>(
    new TurntableAnimator(canvasRef.current, asset, parentRef.current)
  );

  const hasTurntable = asset?.meta?.has_turntable;

  useEffect(() => {
    animator.update(canvasRef.current, asset, parentRef.current);
  }, [asset, canvasRef]);

  if (!hasTurntable) {
    return <ImageView src={"./thumbnail_404.png"} />;
  }

  return (
    <div style={{ width: "100%", border: "1px solid #333" }} ref={parentRef}>
      <canvas style={{ width: "100%" }} ref={canvasRef}></canvas>
    </div>
  );
};
