import React, { Fragment } from "react";
import { usePermissions } from "@refinedev/core";

interface RequiresRoleProps {
  children: React.ReactNode;
  role: string;
}

/**
 * A component that requires a specific role to view its children. Useful for
 * restricting access to certain routes or components based on the user's role.
 *  
 * @param role a string representing the role required to view the children
 * @returns 
 */
export const RequiresRole: React.FC<RequiresRoleProps> = ({
  children,
  role,
}) => {
  const { data } = usePermissions();

  const permissions = data as string[] | null;

  if (permissions?.includes(role)) {
    return <Fragment>{children}</Fragment>;
  }
  return <div>
    <h1>Unauthorized</h1>
    <p>You are not authorized to view this page (requires <code>{role}</code> role) </p>
  </div> 
};
