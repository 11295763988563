import { useState } from "react";
import { ABFSFileUploadQueue } from "../../lib/ABFSUploader";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import type {
  UploadRequestError,
  UploadProgressEvent,
} from "rc-upload/lib/interface";
import { message, Space, Upload as _Upload } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useBlobStorageToken } from "../../hooks/useBlobStorageToken";
import useConnectivityState from "../../hooks/useConnectivityState";
import { SourceDatasetSelect } from "../../components/SourceDatasetSelect";
import { LicenseSelect } from "../../components/LicenseSelect";
import { useSirenAPI } from "../../hooks/useSirenAPI";
import SirenAPI from "../../lib/SirenAPI";
import Asset from "../../lib/Asset";
import { useGetIdentity } from "@refinedev/core";

const { Dragger } = _Upload;

const ABFS_ACCOUNT = "odyflyte";

const makeProps = (
  uploadQueue: ABFSFileUploadQueue,
  sirenAPI: SirenAPI,
  container: string,
  sourceDatasetId: string,
  licenseId: string,
  user: { email: string }
): UploadProps => {
  const props: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: async ({ file, onSuccess, onError, onProgress }) => {
      const cFile = file as File;

      // not the _most_ reliable way to identify local file uniqueness, but probably
      // good enough for our purposes. could always switch to a hash if needed at the
      // cost of some complexity and performance
      //
      // we use this to avoid creating a new uuid if the user selects the same file
      // (whether by accident or in the event of a retry after a failure)
      const uid = `${cFile.name}-${cFile.size}-${cFile.lastModified}`.replace(
        " ",
        "_"
      );
      // check to see if we've already tried to upload this file by checking local
      // storage for its uid if it's there, use the stored asset_id, otherwise,
      // generate a new one. Re-using the asset_id will result in `ABFSFileUpload`
      // resuming the upload from where it left off (because the blob storage key
      // will be the same) or overwriting the existing file if a previous upload
      // was successful.
      const stored = localStorage.getItem(uid);
      let assetId = "";
      if (stored) {
        assetId = stored;
      } else {
        assetId = uuidv4();
        localStorage.setItem(uid, assetId);
      }
      uploadQueue.push(container, sourceDatasetId, assetId, cFile as File);
      uploadQueue.addEventListener("progress", (event: Event) => {
        const cevent = event as CustomEvent;
        if (cevent.detail.assetId !== assetId) {
          return;
        }
        if (onProgress) {
          const evt = {
            percent: cevent.detail.percentage,
          } as UploadProgressEvent;
          onProgress(evt);
        }
      });
      uploadQueue.addEventListener("complete", (event: Event) => {
        const cEvent = event as CustomEvent;
        if (cEvent.detail.assetId !== assetId) {
          return;
        }
        const url = cEvent.detail.url;
        // add the asset to the Siren API
        const asset: Asset = {
          id: assetId,
          type: "glb",
          description: undefined,
          parent_id: undefined,
          license_id: licenseId,
          source_dataset_id: sourceDatasetId,
          url,
          meta: { created_by: user.email, original_filename: cFile.name },
        };
        try {
          sirenAPI.addAsset(asset);
        } catch (e) {
          console.error(`Failed to add asset: ${e}`);
          return;
        }

        // trigger the thumbnail / turntable job
        // POST https://pubsub.api.odyssey.systems/gltf-thumbnail-request
        // { asset_id: asset_id }
        try {
          sirenAPI.requestGLTFThumbnailTask(assetId);
        } catch (e) {
          console.error(`Failed to request thumbnail task: ${e}`);
        }
        if (onSuccess) {
          onSuccess("Upload successful");
        }
      });
      uploadQueue.addEventListener("error", (event: Event) => {
        if ((event as CustomEvent).detail.assetId !== assetId) {
          return;
        }
        if (onError) {
          const evt = {} as UploadRequestError;
          onError(evt);
        }
      });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return props;
};

export const Upload: React.FC = () => {
  const [online] = useConnectivityState();
  const [storageTokenLoading, storageTokenError, storageToken] =
    useBlobStorageToken(ABFS_ACCOUNT);
  const [uploadQueue] = useState(
    new ABFSFileUploadQueue(ABFS_ACCOUNT, storageToken as string)
  );
  const [licenseId, setLicenseId] = useState<string>("");
  const [sourceDatasetId, setSourceDatasetId] = useState<string>("");
  const sirenAPI = useSirenAPI();
  const { data: user } = useGetIdentity();

  if (storageTokenLoading || !user) {
    return <p>Loading...</p>;
  }
  if (storageTokenError) {
    return <p>Error: {storageTokenError.message}</p>;
  }
  uploadQueue.setStorageToken(storageToken as string);
  const disabled = !online || !licenseId || !sourceDatasetId;
  const props = makeProps(
    uploadQueue,
    sirenAPI,
    "glb",
    sourceDatasetId,
    licenseId,
    user as { email: string }
  );
  return (
    <>
      <h1>Upload GLB</h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        {!online && (
          <p>
            You are offline. Uploads are paused. Please check your network
            settings.
          </p>
        )}
        <SourceDatasetSelect
          onSelect={(sourceDataset) => setSourceDatasetId(sourceDataset.id)}
        />
        <LicenseSelect onSelect={(license) => setLicenseId(license.id)} />
        <Dragger
          {...props}
          disabled={disabled}
          accept=".glb,model/gltf-binary,model/gltf_binary"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {disabled
              ? "Select a source dataset and license to upload"
              : "Click or drag files to this area to upload"}
          </p>
        </Dragger>
      </Space>
    </>
  );
};
