import { Collapse, Divider, List, Space, Spin } from "antd";
import { useBlobStorageListFiles } from "../hooks/useBlobStorageListFiles";
import type { BlobData } from "../hooks/useBlobStorageListFiles";
import { useBlobStorageToken } from "../hooks/useBlobStorageToken";
import { useEffect, useState } from "react";

type FileEntryProps = {
  blob: BlobData;
  storage: {
    account: string;
    container: string;
    token: string;
  };
};

type JsonEntryProps = {
  display: string;
  url: string;
};

const JsonEntry: React.FC<JsonEntryProps> = ({ display, url }) => {
  const [json, setJson] = useState<string | null>(null);
  useEffect(() => {
    if (display) {
      fetch(url)
        .then((resp) => resp.json())
        .then((json) => setJson(json)),
        [url];
    }
  }, [display, url]);
  if (json === null) {
    return <p>loading...</p>;
  }
  return (
    <div>
      <Space direction="vertical">
        <div>
          <a href={url}>{display}</a>
        </div>
        <Collapse
          items={[
            {
              key: "view",
              label: "View",
              children: <pre>{JSON.stringify(json, null, 2)}</pre>,
            },
          ]}
        />
      </Space>
    </div>
  );
};

const FileEntry: React.FC<FileEntryProps> = ({ blob, storage }) => {
  const disp = blob.name.split("/").slice(-1)[0];
  const href = `https://${storage.account}.blob.core.windows.net/${storage.container}/${blob.name}${storage.token}`;
  const ext = disp.split(".").slice(-1)[0];
  if (ext === "json") {
    return <JsonEntry url={href} display={disp} />;
  }
  return (
    <>
      <a href={href}>{disp}</a> <pre>{blob.length} bytes</pre>
    </>
  );
};

export const BlobStorageFileList = ({ url }: { url: string | null }) => {
  const [tokenLoading, tokenError, storageToken] =
    useBlobStorageToken("odyflyte");
  const [filesLoading, fileLoadError, files] = useBlobStorageListFiles(
    storageToken,
    url
  );
  if (tokenLoading || filesLoading) {
    return <Spin />;
  }

  if (tokenError || fileLoadError || !storageToken || !files || !url) {
    return <p>error loading files</p>;
  }

  const [, , account, container, ,] = url.split("/");

  return (
    <>
      <List>
        {files.map((blob: BlobData) => {
          return (
            <List.Item>
              <FileEntry
                key={blob.name}
                blob={blob}
                storage={{ account, container, token: storageToken }}
              />
            </List.Item>
          );
        })}
      </List>
      <Divider />
    </>
  );
};
