import React, { useState, Suspense } from "react";

import { Color } from "three";
import { Canvas } from "@react-three/fiber";
import { Environment, Grid, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Input, Space } from "antd";
const { Search } = Input;

export const Gen3D: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [scene, setScene] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const loader = new GLTFLoader();

  function onLoad(gltf: any) {
    setStatus("");
    setScene(gltf.scene);
    setLoading(false);
  }
  function onError(err: any) {
    setStatus(`Error loading glb: ${err}`);
    setError(err);
    setLoading(false);
  }

  async function onPrompt(prompt: string) {
    setLoading(true);
    setStatus("Generating...");
    try {
      const ret = await fetch("https://gen3d.api.odyssey.systems/v2/generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: prompt,
        }),
      });
      const data = await ret.json();
      const mesh = data.meshes[0];
      const url = mesh.url;
      setStatus("Fetching GLB...");
      const glbRes = await fetch(url);
      const glbData = await glbRes.arrayBuffer();
      setStatus("Parsing and Rendering...");
      loader.parse(glbData, "", onLoad, onError);
    } catch (e) {
      setLoading(false);
      setStatus(`Error: ${e}`);
      setError(e);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <h1>Gen3D</h1>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Search
          placeholder="how about a 3d model of a cat?"
          enterButton={"Generate"}
          size="large"
          onSearch={onPrompt}
          loading={loading}
        />
        <p>{status}</p>
        <Suspense>
          <div>
            <Canvas style={{ width: "100%", minHeight: 640, height: 640 }}>
              <OrbitControls />
              <Grid
                cellColor={new Color(0.8, 0.8, 0.8)}
                sectionColor={new Color(0.8, 0.8, 0.8)}
                infiniteGrid
                fadeDistance={25}
              />
              {scene ? <primitive object={scene} /> : <></>}
              <Environment background preset="forest" blur={0.1} />
            </Canvas>
          </div>
        </Suspense>
      </Space>
    </div>
  );
};
