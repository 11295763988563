import SirenAPI from "../lib/SirenAPI";
import { useDataProvider, useGetIdentity } from "@refinedev/core";

interface IIdentity {
  session: {
    access_token: string;
  };
}

export const useSirenAPI = () => {
  const dataProvider = useDataProvider();
  const { data } = useGetIdentity<IIdentity>();

  return new SirenAPI(dataProvider(), data?.session.access_token || "");
};
