import React, { useState } from "react";
import { supabaseClient } from "../utility";
import { Dropdown } from "antd";

import type { DropdownProps, MenuProps } from "antd";
import type { MenuClickEventHandler, MenuInfo } from "rc-menu/lib/interface";
import { Check, FilePlus } from "react-feather";

interface IAssetAddToDatasetButtonProps {
  assetId: string;
  button?: boolean;
}

interface IDatasetMenuItem {
  key: string;
  label: string;
  in_dataset: boolean;
  icon: JSX.Element;
}

export const AssetAddToDatasetButton: React.FC<
  IAssetAddToDatasetButtonProps
> = ({ button, assetId }) => {
  // select all from tags with a bool for whether asset_id is in the many-to-many map (left join datasets and asset_datasets)
  const [data, setData] = useState<IDatasetMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const getDatasets = async () => {
    const { data, error } = await supabaseClient
      .from("datasets")
      .select("id, name, dataset_assets(asset_id)")
      .eq("dataset_assets.asset_id", assetId);
    if (error) {
      console.error("error fetching datasets", error);
      setLoading(false);
      return;
    }
    const items = data.map((dataset) => {
      const in_dataset: boolean =
        dataset.dataset_assets && dataset.dataset_assets.length == 1;
      const icon = in_dataset ? <Check size={12} /> : <></>;
      return {
        key: dataset.id,
        label: dataset.name,
        in_dataset: in_dataset,
        icon,
      };
    }, {});
    setData(items);
    setLoading(false);
  };

  const toggleDataset = async (datasetId: string) => {
    const data = items.find((item: { key: string }) => item.key === datasetId);
    if (!data) {
      console.error("dataset not found", datasetId);
      return;
    }
    if (data.in_dataset) {
      // remove from dataset
      const { error } = await supabaseClient
        .from("dataset_assets")
        .delete()
        .eq("dataset_id", datasetId)
        .eq("asset_id", assetId);
      if (error) {
        // TODO(art): show error toast
        console.error("error deleting dataset_asset", error);
        return;
      }
      await getDatasets();
    } else {
      // add to dataset
      const { error } = await supabaseClient
        .from("dataset_assets")
        .insert({ dataset_id: datasetId, asset_id: assetId });
      if (error) {
        // TODO(art): show error toast
        console.error("error inserting dataset_asset", error);
        return;
      }
      await getDatasets();
    }
  };

  const handleOpenChange: DropdownProps["onOpenChange"] = async (
    nextOpen,
    info
  ) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
    await getDatasets();
  };

  const handleMenuClick: MenuClickEventHandler = async (e: MenuInfo) => {
    if (loading || Object.keys(data).length === 0) {
      // no-op
      return;
    }
    toggleDataset(e.key);
  };

  let items = data;
  if (loading) {
    items = [{ key: "0", label: "Loading...", in_dataset: false, icon: <></> }];
  }
  if (Object.keys(items).length === 0) {
    items = [
      { key: "0", label: "No datasets", in_dataset: false, icon: <></> },
    ];
  }

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      {button ? (
        <Dropdown.Button
          icon={<FilePlus size={12} color="red" />}
          danger
          title="Add to Dataset..."
        >
          Add to Dataset...
        </Dropdown.Button>
      ) : (
        <Dropdown menu={menuProps} open={open} onOpenChange={handleOpenChange}>
          <div>
            <FilePlus size={18} />
          </div>
        </Dropdown>
      )}
    </>
  );
};
