import React, { useEffect } from "react";
import {
  CrudFilters,
  IResourceComponentsProps,
  useInfiniteList,
} from "@refinedev/core";
import { Flex, List, Spin } from "antd";
import { AssetCard } from "../../components/AssetCard";
import { useBlobStorageToken } from "../../hooks/useBlobStorageToken";
import { useDocumentTitle } from "@refinedev/react-router-v6";
import { useSearchParams } from "react-router-dom";
import { useAssetArchive } from "../../hooks/useAssetArchive";
import { useAssetDelete } from "../../hooks/useAssetDelete";
import Asset from "../../lib/Asset";

interface IAssetCardListProps extends IResourceComponentsProps {
  onAssetSelect?: (asset: Asset) => void;
}

export const AssetCardList: React.FC<IAssetCardListProps> = ({
  onAssetSelect,
}) => {
  // set up search params for source dataset
  // n.b., this is a good template for other search filters, e.g., type, state, dataset, etc.
  const [searchParams, setSearchParams] = useSearchParams();
  // the values of the source dataset filter
  const sourceDatasets: string[] =
    searchParams.get("sourceDatasetFilter")?.split(",") ?? [];

  const filters: CrudFilters | undefined =
    sourceDatasets.length > 0
      ? [
          {
            field: "source_dataset_id",
            operator: "in",
            value: sourceDatasets,
          },
        ]
      : undefined;

  const {
    data,
    isError,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteList<Asset>({
    resource: "data_processed_assets",
    pagination: {
      pageSize: 25,
    },
    filters: filters,
    meta: {
      count: "estimated",
    },
  });

  useDocumentTitle("Processed: GLB | Odyssey Siren");

  // const { show } = useNavigation();
  const handleArchive = useAssetArchive();
  const handleDelete = useAssetDelete();

  useEffect(() => {
    // as the user scrolls near the bottom of the current list,
    // fetch the next page of data from the infinite list hook
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
          document.documentElement.scrollHeight - 100 ||
        !hasNextPage
      ) {
        return;
      }
      fetchNextPage();
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, fetchNextPage]);

  // TODO(art): this should really be done centrally and cached
  const [storageTokenLoading, storageTokenError, storageToken] =
    useBlobStorageToken("odyflyte");

  if (isLoading || storageTokenLoading) {
    return (
      <Flex align="center" justify="center" style={{ height: "256px" }}>
        <Spin />
      </Flex>
    );
  }
  if (isError || storageTokenError) {
    /* TODO(art) better error presentation */
    return <div>error</div>;
  }

  const uniqueSet = new Set<string>();
  const allData = (data?.pages?.flatMap((page) => page.data) ?? [])
    .filter((item) => {
      // remove duplicates
      if (uniqueSet.has(item.id as string)) {
        return false;
      }
      uniqueSet.add(item.id as string);
      if (item.delete || item.archived) {
        return false;
      }
      return true;
    })
    .map((item) => {
      item.makeAuthorizedURL = (file: string): string => {
        const [, , account, container, source_dataset_id, asset_id] =
          item.url.split("/");
        const url = `https://${account}.blob.core.windows.net/${container}/${source_dataset_id}/${asset_id}/${file}${storageToken}`;
        return url;
      };
      if (item.type == "glb") {
        item.thumbnail_url = item.makeAuthorizedURL("thumbnail.png");
      } else if (item.type == "hdr") {
        item.thumbnail_url = item.makeAuthorizedURL("thumbnail.jpg");
      }
      return item;
    });

  const onSourceDatasetFilterChange = (v: string[]) => {
    setSearchParams((prev) => {
      if (v.length === 0) {
        prev.delete("sourceDatasetFilter");
        return prev;
      }
      return { ...prev, sourceDatasetFilter: v.join(",") };
    });
  };

  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 4, xxl: 6 }}
      dataSource={allData}
      loading={isFetchingNextPage}
      renderItem={(item: Asset) => (
        <List.Item key={item.id}>
          <AssetCard
            asset={item}
            onArchive={() => {
              if (item) {
                handleArchive(item);
              }
            }}
            onClick={() => {
              if (item.id) {
                onAssetSelect?.(item);
                // show("assets", item.id);
              }
            }}
            onDelete={() => {
              if (item) {
                handleDelete(item);
              }
            }}
          />
        </List.Item>
      )}
    />
  );
};
