import { useState } from "react";
import { useSelect } from "@refinedev/antd";
import { Select } from "antd";

interface SourceDataset {
  id: string;
  name: string;
}

interface SourceDatasetSelectProps {
  onSelect: (sourceDataset: SourceDataset) => void;
}

export const SourceDatasetSelect: React.FC<SourceDatasetSelectProps> = ({
  onSelect,
}) => {
  const [filterString, setFilterString] = useState<string>("");
  const { selectProps: sourceDatasetSelectProps } = useSelect<SourceDataset>({
    resource: "source_datasets",
    optionValue: "id",
    optionLabel: "name",
    filters: [
      {
        field: "name",
        operator: "contains",
        value: filterString,
      },
    ],
  });

  const onSearch = (value: string) => {
    // TODO(art): debounce
    if (value === filterString) {
      return;
    }
    setFilterString(value);
  };

  const _onSelect = (option: { value: string; label: string }) => {
    if (onSelect) {
      onSelect({ id: option.value, name: option.label });
    }
  };

  return (
    <Select
      placeholder="Select a source dataset"
      {...sourceDatasetSelectProps}
      style={{ width: "50%" }}
      filterOption={false}
      onSearch={onSearch}
      onSelect={(_, o) => _onSelect(o as { value: string; label: string })}
    />
  );
};
