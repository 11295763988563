import { useUpdate, useInvalidate } from "@refinedev/core";

export const useAssetArchive = () => {
  const { mutate } = useUpdate();
  const invalidate = useInvalidate();

  const handleArchive = (asset: any) => {
    mutate({
      resource: "assets",
      values: {
        archived: true,
      },
      id: asset.id,
      successNotification: () => {
        invalidate({ resource: "data_processed_glb", invalidates: ["list"] });
        asset.archived = true;
        return {
          message: `Asset ${asset.id} archived`,
          type: "success",
        };
      },
    });
  };
  return handleArchive;
};
