import { DataProvider } from "@refinedev/core";
import Asset from "../lib/Asset";

export default class SirenAPI {
  public readonly dataProvider: DataProvider;
  private readonly jwt: string;

  constructor(dataProvider: DataProvider, jwt: string) {
    this.dataProvider = dataProvider;
    this.jwt = jwt;
  }

  async addAsset(asset: Asset) {
    return this.dataProvider.create({
      resource: "assets",
      variables: {
        id: asset.id,
        type: asset.type,
        description: asset.description,
        parent_id: asset.parent_id,
        license_id: asset.license_id,
        source_dataset_id: asset.source_dataset_id,
        url: asset.url,
        meta: asset.meta,
      },
    });
  }

  async requestGLTFThumbnailTask(assetId: string) {
    const res = await fetch(
      "https://pubsub.api.odyssey.systems/gltf-thumbnail-request",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.jwt,
        },
        body: JSON.stringify({ asset_id: assetId }),
      }
    );
    console.log(
      `response from gltf-thumbnail-request: ${res.status} ${res.statusText}`
    );
  }
}
