import React from "react";
import {
  Image,
  Collapse,
  Descriptions,
  DescriptionsProps,
  Space,
  Tag,
  Tabs,
  Typography,
} from "antd";
import { TurntableViewer } from "./TurntableViewer";
import { ThreeDViewer } from "./ThreeDViewer";
import { useOne } from "@refinedev/core";
import { DateField, TextField } from "@refinedev/antd";
import { BlobStorageFileList } from "./BlobStorageFileList";
import { AssetTags } from "./AssetTags";
import Asset from "../lib/Asset";

interface IAssetInspectorProps {
  asset: Asset;
}

export const AssetInspector: React.FC<IAssetInspectorProps> = ({ asset }) => {
  const { data: sourceDatasetData, isLoading: sourceDatasetIsLoading } = useOne(
    {
      resource: "source_datasets",
      id: asset?.source_dataset_id || "",
      queryOptions: {
        enabled: !!asset,
      },
    }
  );

  const glbUrl = asset.makeAuthorizedURL
    ? asset.makeAuthorizedURL(`${asset.id}.glb`)
    : "";

  const propertyItems: DescriptionsProps["items"] = [
    {
      key: "0",
      label: "Description",
      children: (
        <Typography.Paragraph>{asset?.description}</Typography.Paragraph>
      ),
    },
    {
      key: "7",
      label: "Tags",
      children: <AssetTags asset={asset} />,
    },
    {
      key: "1",
      label: "Asset Id",
      children: (
        <Typography.Text code copyable>
          {asset?.id}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      label: "Type",
      children: asset?.type,
    },
    {
      key: "3",
      label: "Source Dataset",
      children: sourceDatasetIsLoading
        ? "Loading..."
        : sourceDatasetData?.data?.name,
    },
    {
      key: "4",
      label: "License",
      children: asset?.license_id,
    },
    {
      key: "5",
      label: "Parent Asset",
      children: asset?.parent_id ? (
        <a href={`/assets/${asset?.parent_id}`}>{asset?.parent_id}</a>
      ) : (
        <TextField value="None" />
      ),
    },
    {
      key: "6",
      label: "Created At",
      children: <DateField value={asset?.created_at} />,
    },
  ];
  const tabItems = [
    {
      key: "1",
      label: "Thumbnail",
      children: <Image src={asset?.thumbnail_url || "./thumbnail_404.png"} />,
    },
    {
      key: "2",
      label: "Turntable",
      children: <TurntableViewer asset={asset} />,
      disabled: asset?.type === "hdr",
    },
    {
      key: "3",
      label: "3D",
      children: <ThreeDViewer url={glbUrl} />,
      disabled: asset?.type === "hdr",
    },
  ];
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Tabs defaultActiveKey="1" items={tabItems} style={{ width: "100%" }} />
      <Descriptions
        title="Asset Detail"
        bordered
        items={propertyItems}
        column={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
      />
      <Collapse
        items={[
          {
            key: "files",
            label: "Files",
            children: <BlobStorageFileList url={asset?.url} />,
          },
          {
            key: "meta",
            label: "Meta",
            children: <pre>{JSON.stringify(asset?.meta, null, 2)}</pre>,
          },
        ]}
      />
    </Space>
  );
};
