import React from "react";
import { IResourceComponentsProps, useShow, useOne } from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Flex, Space, Spin } from "antd";
import { useBlobStorageToken } from "../../hooks/useBlobStorageToken";
import { useDocumentTitle } from "@refinedev/react-router-v6";
import { AssetArchiveButton } from "../../components/AssetArchiveButton";
import { AssetDeleteButton } from "../../components/AssetDeleteButton";
import { useAssetArchive } from "../../hooks/useAssetArchive";
import { useAssetDelete } from "../../hooks/useAssetDelete";
import { AssetInspector } from "../../components/AssetInspector";
import Asset from "../../lib/Asset";

export const AssetShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const handleArchive = useAssetArchive();
  const handleDelete = useAssetDelete();
  const { data, isLoading } = queryResult;

  useDocumentTitle(`Asset: ${data?.data?.id} | Odyssey Siren`);

  // TODO(art): this should really be done centrally and cached
  const [storageTokenLoading, storageTokenError, storageToken] =
    useBlobStorageToken("odyflyte");

  const asset = data?.data as Asset;

  const { data: sourceDatasetData, isLoading: sourceDatasetIsLoading } = useOne(
    {
      resource: "source_datasets",
      id: asset?.source_dataset_id || "",
      queryOptions: {
        enabled: !!asset,
      },
    }
  );

  if (isLoading || storageTokenLoading || sourceDatasetIsLoading) {
    return (
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <Spin size="large" />
      </Flex>
    );
  }

  // let asset_url: string | null = null;
  if (asset.url && storageToken) {
    asset.makeAuthorizedURL = (file: string): string => {
      const [, , account, container, source_dataset_id, asset_id] =
        asset.url.split("/");
      const url = `https://${account}.blob.core.windows.net/${container}/${source_dataset_id}/${asset_id}/${file}${storageToken}`;
      return url;
    };
    // TODO(art): Get thumbnail from asset metadata and only fallback to these if it isn't present
    if (asset.type == "glb") {
      asset.thumbnail_url = asset.makeAuthorizedURL("thumbnail.png");
    } else if (asset.type == "hdr") {
      asset.thumbnail_url = asset.makeAuthorizedURL("thumbnail.jpg");
    }
  }

  return (
    <Show
      isLoading={isLoading || storageTokenLoading}
      headerButtons={[
        <AssetArchiveButton button onArchive={() => handleArchive(asset)} />,
        <AssetDeleteButton button onDelete={() => handleDelete(asset)} />,
      ]}
    >
      <AssetInspector asset={asset} />
    </Show>
  );
};
