import { Tag } from "antd";
import { useList } from "@refinedev/core";
import type { IResourceComponentsProps } from "@refinedev/core";
import Asset from "../lib/Asset";

interface IAssetTags extends IResourceComponentsProps {
  asset: Asset;
}

interface IAssetTag {
  asset_tags: {
    tag: string;
  };
}

export const AssetTags: React.FC<IAssetTags> = ({ asset }) => {
  const { data, isLoading } = useList({
    resource: "assets_tags_rel",
    filters: [
      {
        field: "asset_id",
        operator: "eq",
        value: asset.id,
      },
    ],
    meta: {
      select: "asset_tags(tag)",
      count: "estimated",
    },
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return <div>No data</div>;
  }
  const tags = data.data as IAssetTag[];
  return (
    <div>
      {tags.map((tag: IAssetTag) => {
        return <Tag key={tag.asset_tags.tag}>{tag.asset_tags.tag}</Tag>;
      })}
    </div>
  );
};
