import {
  BlockBlobClient,
  BlobServiceClient,
  ContainerClient,
  BlockBlobGetBlockListResponse,
  StoragePipelineOptions,
} from "@azure/storage-blob";
import { url } from "inspector";

interface WorkItem {
  container: string;
  sourceDatasetId: string;
  assetId: string;
  file: File;
}
/**
 * A queue for managing uploads to ABFS. This will upload files in parallel
 * with a maximum concurrency of `concurrency`. See `ABFSFileUpload` for
 * more details on how the files are uploaded.
 */
export class ABFSFileUploadQueue extends EventTarget {
  private queue: WorkItem[] = [];
  private storageAccount: string;
  private sasToken: string;
  private concurrency = 2;
  private running = false;
  private workers: Promise<WorkItem>[] = [];

  constructor(storageAccount: string, sasToken: string, concurrency = 2) {
    super();
    this.concurrency = concurrency;
    this.storageAccount = storageAccount;
    this.sasToken = sasToken;
  }

  setStorageToken(storageToken: string) {
    this.sasToken = storageToken;
  }

  getDepth(): number {
    return this.queue.length;
  }

  push(
    container: string,
    sourceDatasetId: string,
    assetId: string,
    file: File
  ) {
    this.queue.push({ container, sourceDatasetId, assetId, file });
    this.process();
  }

  async _uploadNext() {
    return new Promise<WorkItem>((resolve) => {
      const workItem = this.queue.shift();
      if (!workItem) {
        throw new Error("Queue is empty");
      }
      const upload = new ABFSFileUpload(
        this.storageAccount,
        this.sasToken,
        workItem.container,
        workItem.sourceDatasetId,
        workItem.assetId,
        workItem.file
      );
      upload.addEventListener("progress", (event: Event) => {
        const cevent = event as CustomEvent;
        this.dispatchEvent(
          new CustomEvent("progress", {
            detail: {
              assetId: workItem.assetId,
              uploadedBytes: cevent.detail.uploadedBytes,
              totalBytes: workItem.file.size,
              percentage: cevent.detail.percentage,
            },
          })
        );
      });
      upload.addEventListener("complete", (event: Event) => {
        const cEvent = event as CustomEvent;
        this.dispatchEvent(
          new CustomEvent("complete", {
            detail: {
              assetId: workItem.assetId,
              url: cEvent.detail.url,
            },
          })
        );
        resolve(workItem);
      });
      upload.addEventListener("error", (event: Event) => {
        const cEvent = event as CustomEvent;
        this.dispatchEvent(
          new CustomEvent("error", {
            detail: {
              assetId: workItem.assetId,
              error: cEvent.detail.error,
            },
          })
        );
        resolve(workItem);
      });
      upload.start();
    });
  }

  async process() {
    for (let i = this.workers.length; i < this.concurrency; i++) {
      if (this.queue.length === 0) {
        break;
      }
      try {
        const worker = this._uploadNext().finally(() => {
          this.workers.splice(this.workers.indexOf(worker), 1);
          this.process();
        });
        this.workers.push(worker);
      } catch (e) {
        console.warn("Error creating worker", e);
      }
    }
  }
}

export class ABFSFileUpload extends EventTarget {
  private readonly blobServiceClient: BlobServiceClient;
  private running = true;
  static CHUNK_SIZE = 4 * 1024 * 1024;
  private storageAccount: string;
  private container: string;
  private sourceDatasetId: string;
  private assetId: string;
  private file: File;
  private path: string;
  private containerClient: ContainerClient | null = null;
  private blockBlobClient: BlockBlobClient | null = null;
  private blockSize = ABFSFileUpload.CHUNK_SIZE;
  private blockIds: string[] = [];
  private blockIndex = 0;
  private uploadedBytes = 0;

  constructor(
    storageAccount: string,
    sasToken: string,
    container: string,
    sourceDatasetId: string,
    assetId: string,
    file: File
  ) {
    super();
    this.storageAccount = storageAccount;
    this.container = container;
    this.sourceDatasetId = sourceDatasetId;
    this.assetId = assetId;
    this.file = file;
    this.path = this.makePath();
    window.addEventListener("online", () => {
      if (!this.running) {
        this.running = true;
        this.resume();
      }
    });
    window.addEventListener("offline", () => {
      this.pause();
    });
    const options: StoragePipelineOptions = {
      retryOptions: {
        maxTries: 4, // Maximum retry attempts
        tryTimeoutInMs: 30000, // Maximum time allowed for each try
        retryDelayInMs: 5000, // Delay between retries
        maxRetryDelayInMs: 10000, // Maximum delay between retries
      },
    };

    this.blobServiceClient = new BlobServiceClient(
      `https://${storageAccount}.blob.core.windows.net?${sasToken}`,
      undefined,
      options
    );
  }

  private makePath(): string {
    return `${this.sourceDatasetId}/${this.assetId}/${
      this.assetId
    }.${this.file.name.split(".").pop()}`;
  }

  private makeUrl(): string {
    return `abfs://${this.storageAccount}/${this.container}/${this.path}`;
  }

  async start() {
    this.containerClient = this.blobServiceClient.getContainerClient(
      this.container
    );
    this.blockBlobClient = this.containerClient.getBlockBlobClient(
      `${this.sourceDatasetId}/${this.assetId}/${this.assetId}.${this.file.name
        .split(".")
        .pop()}`
    );
    this.blockSize = ABFSFileUpload.CHUNK_SIZE;
    this.blockIds = [];
    this.blockIndex = 0;
    this.uploadedBytes = 0;
    await this.resume();
  }

  pause() {
    this.running = false;
  }

  async resume() {
    if (!this.blockBlobClient) {
      throw new Error("BlockBlobClient not initialized");
    }
    if (!this.running) {
      return;
    }
    // Load the list of blocks that have already been uploaded
    let existingBlocks: BlockBlobGetBlockListResponse | undefined;
    try {
      existingBlocks = await this.blockBlobClient.getBlockList("uncommitted");
      if (existingBlocks.uncommittedBlocks) {
        console.log(
          `Found existing blocks for '${this.file.name}'. Resuming upload.`
        );
        for (const block of existingBlocks.uncommittedBlocks) {
          this.uploadedBytes += block.size;
        }
      }
    } catch (e) {
      // ignore the error if the blockList doesn't exist
    }
    for (let offset = 0; offset < this.file.size; offset += this.blockSize) {
      if (!this.running) {
        return;
      }
      const blockId = btoa(String(this.blockIndex).padStart(5, "0"));
      if (
        !existingBlocks ||
        !existingBlocks.uncommittedBlocks?.find((v) => v.name === blockId)
      ) {
        const chunk = this.file.slice(offset, offset + this.blockSize);
        try {
          await this.blockBlobClient.stageBlock(blockId, chunk, chunk.size);
        } catch (e) {
          this.dispatchEvent(
            new CustomEvent("error", {
              detail: {
                assetId: this.assetId,
                error: e,
              },
            })
          );
        }
        this.uploadedBytes += chunk.size;
        this.dispatchEvent(
          new CustomEvent("progress", {
            detail: {
              assetId: this.assetId,
              uploadedBytes: this.uploadedBytes,
              totalBytes: this.file.size,
              percentage: (this.uploadedBytes / this.file.size) * 100,
            },
          })
        );
      }

      this.blockIds.push(blockId);
      this.blockIndex++;
    }
    try {
      await this.blockBlobClient.commitBlockList(this.blockIds);
      this.dispatchEvent(
        new CustomEvent("complete", {
          detail: {
            assetId: this.assetId,
            url: this.makeUrl(),
          },
        })
      );
    } catch (e) {
      this.dispatchEvent(
        new CustomEvent("error", {
          detail: {
            assetId: this.assetId,
            error: e,
          },
        })
      );
    }
  }

  /**
   * Upload the file to <container>/<sourceDatasetId>/<assetId>/<file.name>
   * This will upload the file in chunks of 4MB and is resumable. If a previous
   * upload for `<sourceDatasetId>/<assetId>` was interrupted, it will resume
   * from where it left off.
   *
   * @param container
   * @param sourceDatasetId
   * @param assetId
   * @param file
   */
  async uploadFile(
    container: string,
    sourceDatasetId: string,
    assetId: string,
    file: File
  ): Promise<void> {
    const containerClient =
      this.blobServiceClient.getContainerClient(container);

    const blockBlobClient = containerClient.getBlockBlobClient(
      `${sourceDatasetId}/${assetId}/${assetId}.${file.name.split(".").pop()}`
    );
    const blockSize = ABFSFileUpload.CHUNK_SIZE;
    const blockIds = [];
    let blockIndex = 0;
    let uploadedBytes = 0;

    // Load the list of blocks that have already been uploaded
    let existingBlocks: BlockBlobGetBlockListResponse | undefined;
    try {
      existingBlocks = await blockBlobClient.getBlockList("uncommitted");
      if (existingBlocks.uncommittedBlocks) {
        console.log(
          `Found existing blocks for '${file.name}'. Resuming upload.`
        );
        for (const block of existingBlocks.uncommittedBlocks) {
          uploadedBytes += block.size;
        }
      }
    } catch (e) {
      // ignore the error if the blockList doesn't exist
    }

    for (let offset = 0; offset < file.size; offset += blockSize) {
      const blockId = btoa(String(blockIndex).padStart(5, "0"));
      if (
        !existingBlocks ||
        !existingBlocks.uncommittedBlocks?.find((v) => v.name === blockId)
      ) {
        const chunk = file.slice(offset, offset + blockSize);
        try {
          await blockBlobClient.stageBlock(blockId, chunk, chunk.size);
        } catch (e) {
          this.dispatchEvent(
            new CustomEvent("error", {
              detail: {
                assetId,
                error: e,
              },
            })
          );
        }
        uploadedBytes += chunk.size;
        this.dispatchEvent(
          new CustomEvent("progress", {
            detail: {
              assetId,
              uploadedBytes,
              totalBytes: file.size,
              percentage: (uploadedBytes / file.size) * 100,
            },
          })
        );
      }

      blockIds.push(blockId);
      blockIndex++;
    }
    try {
      await blockBlobClient.commitBlockList(blockIds);
      this.dispatchEvent(
        new CustomEvent("complete", {
          detail: {
            assetId,
          },
        })
      );
    } catch (e) {
      this.dispatchEvent(
        new CustomEvent("error", {
          detail: {
            assetId,
            error: e,
          },
        })
      );
    }
  }
}
