/**
 * Refine data provider for accessing blob storage keys
 */

import { supabaseClient } from "./utility";
import type { BaseRecord, DataProvider } from "@refinedev/core";
import { HttpError } from "@refinedev/core";

interface BlobStorageToken {
  storageAccount: string;
  blobStorageToken: string;
  expiresAt: number;
}

const __blobStorageTokenCache: Map<string, BlobStorageToken> = new Map();

export const dataProvider = (): DataProvider => {
  return {
    getOne: async ({ meta, id }) => {
      const acctName: string = (id as string) || id.toString();
      // duration and permissions are optional, default to 1 hour and "cwrlf"
      const durationNum = (meta?.duration as number) || 60 * 60;
      const permissionsStr = (meta?.permissions as string) || "cwrlf";
      const memoKey = `${acctName}-${durationNum}-${permissionsStr}`;
      const retToken = __blobStorageTokenCache.get(memoKey);
      if (retToken) {
        // TODO(art): check token expiration
        console.log("cached blob storage token");
        return {
          data: retToken as any,
        };
      }

      const { data: sessionData } = await supabaseClient.auth.getSession();
      console.log("session data");
      console.log(sessionData);
      const accessToken = sessionData.session?.access_token;
      if (!accessToken) {
        throw {
          message: "blobStorageDataProvider: no session access token",
        } as HttpError;
      }
      const res = await fetch(
        "https://vizglusznacdygtioulv.supabase.co/functions/v1/generate-blob-storage-key",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            storage_account: acctName,
            duration: durationNum,
            permissions: permissionsStr,
          }),
        }
      );
      if (res.status !== 200) {
        throw new Error(`${res.status}`);
      }
      const payload = await res.json();
      const token: BlobStorageToken = {
        expiresAt: Date.now() + durationNum * 1000,
        blobStorageToken: payload.blob_storage_token,
        storageAccount: acctName,
      };
      console.log(
        `fetched blob storage token ${token.blobStorageToken}, updating cache`
      );
      __blobStorageTokenCache.set(memoKey, token);
      return {
        data: token,
      };
    },

    create: async () => {
      throw new Error("Not implemented");
    },
    update: async () => {
      throw new Error("Not implemented");
    },
    deleteOne: async () => {
      throw new Error("Not implemented");
    },
    getList: async () => {
      throw new Error("Not implemented");
    },
    getApiUrl: () =>
      "https://vizglusznacdygtioulv.supabase.co/functions/v1/generate-blob-storage-key",
  };
};
