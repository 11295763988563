import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "@refinedev/react-router-v6";

import { supabaseClient } from "../../utility";
import { Card, Col, Row, Space, Spin, Typography } from "antd";

const { Text } = Typography;

const DashboardStatCard: React.FC<{
  title: string;
  loading: boolean;
  value: number;
}> = ({ title, loading, value }) => {
  useDocumentTitle("Dashboard | Odyssey Siren");
  return (
    <Card
      style={{ padding: 0 }}
      styles={{
        body: {
          padding: "8px 8px 8px 12px",
        },
      }}
      size="small"
    >
      <Space direction="vertical">
        <Text>{title}</Text>
        <Text style={{ fontSize: "48px", fontWeight: "600" }}>
          {loading ? <Spin /> : value.toLocaleString("en-US")}
        </Text>
      </Space>
    </Card>
  );
};

export const Dashboard: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const { data, error } = await supabaseClient.rpc("asset_stats");
      setData(data[0]);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Row gutter={[32, 32]}>
        <Col xs={24} sm={24} xl={8}>
          <DashboardStatCard
            title="Total Assets"
            loading={loading}
            value={data?.total}
          />
        </Col>
        <Col xs={24} sm={24} xl={8}>
          <DashboardStatCard
            title="Added Past 7 Days"
            loading={loading}
            value={data?.last_7_days}
          />
        </Col>
        <Col xs={24} sm={24} xl={8}>
          <DashboardStatCard
            title="Added Past 30 Days"
            loading={loading}
            value={data?.last_30_days}
          />
        </Col>
      </Row>
    </div>
  );
};
