import { useEffect, useState } from "react";

export type BlobData = {
  name: string;
  length: number;
};
/**
 *
 * @param url - expects a fully-qualified abfs url
 * @returns
 */
export function useBlobStorageListFiles(
  token: string | null,
  url: string | null
): [boolean, Error | null, BlobData[]] {
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState<BlobData[]>([]);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    async function getFiles(
      token: string,
      storageAccount: string,
      container: string,
      path: string
    ) {
      setLoading(true);
      const url = `https://${storageAccount}.blob.core.windows.net/${container}${token}&restype=container&comp=list&prefix=${path}`;
      try {
        const res = await fetch(url);
        const xml = await res.text();
        const data = new DOMParser().parseFromString(xml, "text/xml");
        const filePaths: BlobData[] = [];
        data.querySelectorAll("Blob").forEach((blob) => {
          const name = blob.querySelector("Name")?.textContent;
          const length = parseInt(
            blob.querySelector("Content-Length")?.textContent ?? "0"
          );
          if (name) {
            filePaths.push({ name, length });
          } else {
            console.warn(
              "unexpected response from ABS: Blob element did not have Name child element "
            );
          }
        });
        setError(null);
        setFiles(filePaths);
      } catch (error) {
        setError(error as Error);
        setFiles([]);
      }
      setLoading(false);
    }
    if (token && url) {
      const [, , account, container, source_dataset_id, asset_id] =
        url.split("/");

      getFiles(token, account, container, `${source_dataset_id}/${asset_id}`);
    }
  }, [token, url]);
  return [isLoading, error, files];
}
