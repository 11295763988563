import React from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { Archive } from "react-feather";

interface IAssetDeleteButtonProps {
  onArchive: () => void;
  button?: boolean;
}

export const AssetArchiveButton: React.FC<IAssetDeleteButtonProps> = ({
  onArchive,
  button,
}) => {
  return (
    <>
      <Popconfirm
        title="Archive Asset"
        description="Are you sure you want to archive this asset?"
        onConfirm={onArchive}
      >
        {button ? (
          <Button icon={<Archive size={12} />} title="Archive">
            Archive
          </Button>
        ) : (
          <div style={{ cursor: "pointer", width: "100%" }}>
            <Archive size={18} />
          </div>
        )}
      </Popconfirm>
    </>
  );
};
