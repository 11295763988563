import { useState, useEffect } from "react";

const useConnectivityState = (): [boolean] => {
  const [online, setOnline] = useState<boolean>(navigator.onLine);

  const updateOnlineStatus = () => {
    setOnline(navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return [online];
};

export default useConnectivityState;
