import { useState } from "react";
import { useSelect } from "@refinedev/antd";
import { Select } from "antd";

/**
 * TODO(art): This is 99% copy/pasted from SourceDatasetSelect. I need to get
 * smarter about how to parameterize types in typescript so this can be more
 * DRY.
 *
 * In particular, `useSelect` needs a type parameter—how can we thread that through
 * from a type parameter on the component?
 */

interface License {
  id: string;
  name: string;
}

interface LicenseSelectProps {
  onSelect: (license: License) => void;
}

export const LicenseSelect: React.FC<LicenseSelectProps> = ({ onSelect }) => {
  const [filterString, setFilterString] = useState<string>("");
  const { selectProps: sourceDatasetSelectProps } = useSelect<License>({
    resource: "licenses",
    optionValue: "id",
    optionLabel: "id",
    filters: [
      {
        field: "id",
        operator: "contains",
        value: filterString,
      },
    ],
  });

  const onSearch = (value: string) => {
    // TODO(art): debounce
    if (value === filterString) {
      return;
    }
    setFilterString(value);
  };

  const _onSelect = (option: { value: string; label: string }) => {
    if (onSelect) {
      onSelect({ id: option.value, name: option.label });
    }
  };

  return (
    <Select
      placeholder="Select a license"
      {...sourceDatasetSelectProps}
      style={{ width: "50%" }}
      filterOption={false}
      onSearch={onSearch}
      onSelect={(_, o) => _onSelect(o as { value: string; label: string })}
    />
  );
};
