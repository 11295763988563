import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany } from "@refinedev/core";
import {
  useSelect,
  useTable,
  List,
  DateField,
  FilterDropdown,
  ShowButton,
} from "@refinedev/antd";
import { Image, Popover, Select, Space, Table, Typography } from "antd";
const { Text } = Typography;
import { useBlobStorageToken } from "../../hooks/useBlobStorageToken";

const getThumbnail = (record: BaseRecord): string | null => {
  if (!record.meta) {
    return null;
  }
  if (record.meta.thumbnails) {
    return record.meta.thumbnails[0];
  }

  if (record.type === "glb" && record.meta.state === "GLB_EXPORT") {
    return "thumbnail.png";
  }

  if (record.type === "hdr") {
    return "thumbnail.jpg";
  }
  return null;
};

const ThumbnailColumn: React.FC<BaseRecord> = ({ record }) => {
  // TODO(art): this should really be done centrally and cached
  const [storageTokenLoading, storageTokenError, storageToken] =
    useBlobStorageToken("odyflyte");
  const thumbnail = getThumbnail(record);
  if (!thumbnail) {
    return <pre>No Thumbnail</pre>;
  }
  if (storageTokenLoading) {
    return <pre>Loading...</pre>;
  }
  if (storageTokenError) {
    return <pre>Error Loading Thumbnail</pre>;
  }

  const [i0, i1, account, container, source_dataset_id, asset_id] =
    record.url.split("/");
  const url = `https://${account}.blob.core.windows.net/${container}/${source_dataset_id}/${asset_id}/${thumbnail}${storageToken}`;
  return (
    <Image
      placeholder={true}
      style={{ maxWidth: "256px", maxHeight: "256px" }}
      src={url}
    />
  );
};

export const AssetList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { data: sourceDatasetData, isLoading: sourceDatasetIsLoading } =
    useMany({
      resource: "source_datasets",
      ids: tableProps?.dataSource?.map((item) => item?.source_dataset_id) ?? [],
      queryOptions: {
        enabled: !!tableProps?.dataSource,
      },
    });

  const { selectProps: sourceDatasetSelectProps } = useSelect({
    resource: "source_datasets",
    optionLabel: "name",
  });

  const { selectProps: licenseSelectProps } = useSelect({
    resource: "licenses",
    optionValue: "id",
    optionLabel: "description",
    // TODO(art) Ideally this would only show the licenses that are in use instead of the
    // entire licenses table (which has every license in SPDX) This filter is probably
    // a good place to start, but it doesn't work yet.
    /*
    filters: [
      {
        field: "id",
        operator: "in",
        value: [... new Set(tableProps?.dataSource?.map((item) => item?.license_id) ?? []),
        ],
      },
    ],
    */
  });

  const { selectProps: assetTypeSelectProps } = useSelect({
    resource: "assets",
    optionValue: "type",
    optionLabel: "type",
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="Id" />
        <Table.Column
          dataIndex="type"
          title="Type"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Asset Type"
                {...assetTypeSelectProps}
              />
            </FilterDropdown>
          )}
        />
        <Table.Column
          title="Thumbnail"
          render={(value) => {
            return <ThumbnailColumn record={value} />;
          }}
        />
        <Table.Column
          dataIndex={["license_id"]}
          title="License"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select License"
                {...licenseSelectProps}
              />
            </FilterDropdown>
          )}
          render={(value) => <pre>{value}</pre>}
        />
        <Table.Column
          dataIndex={["source_dataset_id"]}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Source Dataset"
                {...sourceDatasetSelectProps}
              />
            </FilterDropdown>
          )}
          title="Source Dataset"
          render={(value) =>
            sourceDatasetIsLoading ? (
              <>Loading...</>
            ) : (
              sourceDatasetData?.data?.find((item) => item.id === value)?.name
            )
          }
        />

        <Table.Column
          dataIndex={["created_at"]}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
          sorter
        />
        <Table.Column
          dataIndex={["meta"]}
          title="Meta"
          render={(value: any) => {
            return (
              <Popover content={<pre>{JSON.stringify(value, null, 2)}</pre>}>
                <Text>Meta</Text>
              </Popover>
            );
          }}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
