import React from "react";
import { Flex } from "antd";
import { OdysseyLogo } from "./OdysseyLogo";

export const LoginTitle: React.FC = () => {
  return (
    <Flex align="center" justify="center">
      <div style={{ width: 112, height: 112 }}>
        <OdysseyLogo />
      </div>
    </Flex>
  );
};
