import React from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { Trash } from "react-feather";

interface IAssetDeleteButtonProps {
  onDelete: () => void;
  button?: boolean;
}

export const AssetDeleteButton: React.FC<IAssetDeleteButtonProps> = ({
  button,
  onDelete,
}) => {
  return (
    <Popconfirm
      title="Delete Asset"
      description="Are you sure you want to flag this asset for deletion?"
      onConfirm={onDelete}
    >
      {button ? (
        <Button
          icon={<Trash size={12} color="red" />}
          danger
          title="Flag for Deletion"
        >
          Delete
        </Button>
      ) : (
        <div style={{ cursor: "pointer", width: "100%" }}>
          <Trash size={18} color="red" />
        </div>
      )}
    </Popconfirm>
  );
};
